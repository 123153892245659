export const Auth = () => {
    return (
        <div className="auth">
            <Login />
            <Register />
        </div>
    );
};

const Login = () => {
    return (
        <div>Login</div>
    );
};

const Register = () => {
    return (
        <div className="auth-container">
            <form>
                <h2>Register</h2>
            </form>
        </div>
    );
};